.float-right {
  float: right;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.padded-top {
  padding-top: 1em;
}

.padded {
  padding: 3px;
}

.extra-padding {
  padding: 4em 0;
}

.full-width {
  width: 100%;
}

.some-extra-padding {
  padding: 1em 0;
}

.sticky {
  position: sticky;
  top: 0;
}

.hidden-for-mail-copy {
  color: green;
  display: none;
  white-space: pre;
}

.extra-margin {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  &.single {
    margin-bottom: 12px;
  }
  &.double {
    margin-bottom: 24px;
  }
}

.breakable-long-lines {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}