svg {
  text {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  path {
    transform-origin: center;
  }

  .grid-line {
    pointer-events: none;
  }

  text::selection {
    background: none;
  }

  // TODO outline für hover, aber nicht für die Rahmenelemente
  &.tool-SELECT {
    *[data-selectable=true] {
      //outline: 1px solid magenta;
      &:hover {
        cursor: pointer;
        outline: 1px solid lightgrey;
      }
    }
  }
}