@import 'styles/_typography.scss';
@import 'styles/flexboxgrid.css';
@import 'styles/_animations.scss';
@import 'styles/_tables.scss';
@import 'styles/_svg.scss';
@import 'styles/_elements.scss';
@import 'styles/_helper.scss';
@import 'styles/_layout.scss';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/solid';

// resets not covered by normalized
//TODO: flexbox vendor-prefixen für IE10 https://caniuse.com/#feat=flexbox
html, #root, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  hyphens: auto;
}

#modal {
  position: relative;
  // z-index: 10000;
}


#popup {
  // position: fixed;
}

#flyout {
  //z-index: 9999;
  position: relative;
  overflow: hidden;
}

img {
  width: 100%;
  height: auto;
}

a.no-styled-link {
  text-decoration: none;
  color: inherit;
}

.legal-text {
  background-color: white;
  padding: 10px 12px;
  border-radius: 3px;
  border: 1px solid lightgrey;
}