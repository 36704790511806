.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

@keyframes pulsating{
  0%{	opacity: 0.5;    }
  50%{	opacity: 1;  }
  100%{	opacity: 0.5;    	}
}