a button {
  text-decoration: none;
}

.checkbox-additional {
  padding-left: 1.4em;
}

img.embedded-foto {
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 3px;
}