table {
  width: 100%;
  border-collapse: collapse;

  &.extra-padded {
    td {
      padding: 10px 3px;
    }
  }

  thead {
    font-weight: bold;
    text-align: left;

    th {
      background-color: transparent;
      padding: 5px 3px;
    }
  }

  tbody tr:hover {
    background-color: rgba(255,255,255,0.6);
    color: #1b1e21;
  }

  tr:last-child {
    td {
      border-bottom: none;
    }
  }

  tr td {
    padding: 5px 3px;
    border-bottom: 0.5px solid rgba(0,0,0,0.1);
    vertical-align: top;

    &.icon-cell {
      text-align: center;
      padding: 3px 0;
      min-width: rem;
    }

    &.important {
      font-weight: bold;
      small {
        font-weight: normal;
      }
    }
  }

  &.initial {
    td {
      padding: 0;
      border: 0;
    }

    tr:hover {
      background-color: initial;
    }
  }
}