html {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #231F20;
}

@media only screen and (min-width: 576px) {
  html { font-size: 15px; }
}

@media only screen and (min-width: 768px) {
  html { font-size: 17px; }
}

@media only screen and (min-width: 992px) {
  html { font-size: 18px; }
}

// @font-face {
//   font-family: "Font Awesome 5 Free";
//   weight: 900;
//   src: url("/webfonts/tacpic_swellbraille.woff") format("woff");
// }
// @font-face {
//   font-family: "Font Awesome 5 Free";
//   weight: 400;
//   font-family: "tacpic swell braille";
//   src: url("/webfonts/tacpic_swellbraille.woff") format("woff");
// }

@font-face {
  font-family: "tacpic swell braille";
  src: url("/webfonts/tacpic_swellbraille_euro6f.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  src: url("/webfonts/Roboto-Regular.ttf");
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: Roboto;
  src: url("/webfonts/Roboto-Bold.ttf");
  font-display: swap;
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url("/webfonts/Roboto-Italic.ttf");
  font-display: swap;
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Quicksand;
  src: url("/webfonts/Quicksand-Regular.ttf");
  font-display: swap;
  font-weight: normal;
}

//@font-face {
//  font-family: "Font Awesome 5 Free";
//  src: url("/webfonts/fa-solid-900.ttf") format("ttf"), url("/webfonts/fa-solid-900.woff2") format("woff2");
//  font-weight: normal;
//}

a {
  &.further-info:before {
    font-family: "Font Awesome 5 Free";
    content: "\f0da";
    display: inline-block;
    padding-right: 0.3em;
    transition: padding 0.1s;
  }

  &.further-info:hover:before {
    padding-right: 0.1em;
    padding-left: 0.2em;
  }
}

button, input, textarea, select {
  font-family: "Roboto", sans-serif;
}

figcaption {
  text-align: center;
  font-style: italic;
}

fieldset {
  border: none;
  padding: 0 0 0 0.7em;
  margin: 0;

  &:not(.no-margin) {
    margin-top: 0.5em;
  }
}

legend {
  font-size: 0.9rem;
  padding: 0;
  font-weight: 700;
  width: 100%;
  margin-left: -0.7em;
  //letter-spacing: 0.5px;

  display: flex;
  align-items: center;

  margin-bottom: 0.5em;

  &:after {
    background: #D9D9D9;
    margin-left: 0.5em;
    height: 1px;
    flex: 1;
    content: '';
  }
}

small {
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 0.8;
}

.sub-label {
  font-size: 0.9em;
  display: inline-block;
  font-style: italic;
  line-height: 1.1em;
}

.monospace {
  font-family: monospace;
}

.disabled {
  color: #333;
  font-style: italic;
}

address {
  font-style: normal;
}

p {
  line-height: 1.4rem;

  code {
    background-color: #231F20;
    color: white;
    padding: 0 0.5rem;
    border-radius: 2px;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    // margin-bottom: 0;
  }

  &.disabled {
    color: #4a4a4a;
    border-radius: 3px;
    text-shadow: 1px 1px white;
    font-style: italic;
    font-size: 0.95rem;
  }

  &.smallprint {
    font-size: 0.9rem;
    opacity: 0.9;
  }

  &.copy {
    max-width: 600px;
  }
}

h1, h2, h3, h4, h5, h6 {
  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 2rem;
  //text-decoration: underline;
}

h2 {
  // font-family: "Quicksand", sans-serif;
  //border-bottom: 2px solid #000;
  font-weight: 700;
  font-size: 1.4rem;
}

h3 {
  //font-family: "Roboto", sans-serif;
  //border-bottom: 2px solid #000;
  font-weight: 700;
  font-size: 1.2rem;
}

h4 {
  //font-family: "Roboto", sans-serif;
  //border-bottom: 2px solid #000;
  font-weight: 700;
  font-size: 1.0rem;
}

h5, h6 {
  font-size: 1rem;
}

//section.legal-text {
//  .Haendlerbund_Rechtstext_Titel {
//    font-family: "Quicksand", sans-serif;
//    font-weight: 700;
//    font-size: 1.4em;
//  }
//}

ul {
  padding-left: 1rem;

  li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}